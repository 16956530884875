import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import UploadIcon from "../../assets/svgs/UploadIcon";
import AppModal from "../../components/appModal";
import AppUploadComponent from "../../components/appUpload";
import TextInput from "../../components/TextInput";
import { useAppDispatch, useAppSelector } from "../../redux/reducers/hooks";
import {
  setIsRefetchMusic,
  setIsShowUploadModal,
} from "../../redux/reducers/uploadModalSlice";
import Styles from "./UploadSoundModal.module.scss";
import CheckInput from "../../components/checkInput";
import Button from "../../components/appButton";
import useToast from "../../utilities/hooks/useToast";
import SongUploadIcon from "../../assets/svgs/SongUploadIcon";
import { Link } from "react-router-dom";
import { setMusicEditDetails } from "../../redux/reducers/editSongSlice";
import services from "../../controllers";
const UploadSongModal = () => {
  const toast = useToast();

  const [acceptTerms, setAcceptTerms]: any = useState(false);
  const [loader, setLoader]: any = useState(false);
  const [songCover, setSongCover]: any = useState(null);
  const [imageCover, setImageCover]: any = useState(null);
  const [coverImageLink, setCoverImageLink] = useState("");
  const { userProfile } = useAppSelector((state) => state?.userProfileDetails);
  const [isEditCoverImage, setIsEditCoverImage]: any = useState(false);

  const { musicEditDetails } = useAppSelector(
    (state) => state?.editMusicDetails,
  );
  const dispatch = useAppDispatch();
  const uploadSongValidationSchema = Yup?.object()?.shape({
    title: Yup.string().required("Required"),
    // artiste: Yup.string().required("Required"),
    linkToContent: Yup.string()
      .url("Enter a valid URL,eg., https://example.com")
      .optional(),
    directLink: Yup.string()
      .url("Enter a valid URL,eg., https://example.com")
      .optional(),
    personalMessage: Yup.string(),
  });

  const getError = (message: string) => {
    return toast.error(message);
  };

  useEffect(() => {
    if (musicEditDetails) {
      setAcceptTerms(true);
    }
  }, [musicEditDetails]);

  const uploadSongDetails = (
    imageUloadedLink: string,
    songUploadedLink: string,
    values: any,
  ) => {
    let data = {
      ...(musicEditDetails?.id ? "" : { owner: userProfile?._id }),
      artiste: values?.artiste,
      ...(musicEditDetails?.id ? "" : { audio_file: songUploadedLink }),
      title: values?.title,
      ...(isEditCoverImage ? { cover_image: imageUloadedLink } : ""),
      terms_condition: true,
      ...(values?.spotifyLink ? { spotify_link: values?.spotifyLink } : ""),
      ...(values?.linkToContent ? { link_content: values?.linkToContent } : ""),
      ...(values?.personalMessage
        ? { personal_message: values?.personalMessage }
        : ""),
      ...(values?.directLink ? { direct_link: values?.directLink } : ""),
    };
    setLoader(true);
    let checkIfIsEdit = musicEditDetails?.id
      ? new services().patchService(
          `song/${musicEditDetails?.id}`,
          data,
          "https://trimmer-dev.audoji.app/v1/",
        )
      : new services().postService(
          "song/upload",
          data,
          "https://trimmer-dev.audoji.app/v1/",
        );

    checkIfIsEdit
      .then((res) => {
        setLoader(false);
        if (res?.status === "success") {
          toast.success(res?.message);
          dispatch(setIsRefetchMusic(true));
          dispatch(setIsShowUploadModal(false));
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("an error occurred, try again later.");
      });
  };

  const uploadCoverSong = (
    imageUploadedLink: string,
    song: any,
    values: any,
  ) => {
    let uploadImage = new services().picUpdateService(
      "upload/audio",
      "song",
      song,
      "isAiBaseUrl",
    );
    setLoader(true);

    uploadImage
      .then((res: any) => {
        setLoader(false);
        if (res?.status === "success") {
          return uploadSongDetails(
            imageUploadedLink,
            res?.data?.file_path,
            values,
          );
        }

        toast.error(res?.message);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(`an error occurred, try again later,Error:${error}.`);
      });
  };

  const uploadCoverImage = (imageFile: any, songFile: any, values: any) => {
    if (!imageFile) {
      return uploadCoverSong("", songFile, values);
    }
    let uploadImage = new services().picUpdateService(
      "upload/cover-photo",
      "photo",
      imageFile?.file,
      "isAiBaseUrl",
    );
    setLoader(true);

    uploadImage
      .then((res: any) => {
        setLoader(false);
        if (res?.status === "success") {
          setCoverImageLink(res?.data?.file_path);
          if (!songFile) {
            return uploadSongDetails(
              res?.data?.file_path,
              musicEditDetails?.audio_file,
              values,
            );
          }
          return uploadCoverSong(res?.data?.file_path, songFile, values);
        }

        toast.error(res?.message);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(`an error occurred, try again later,Error:${error}.`);
      });
  };

  const uploadSong = (values: any) => {
    // if (!imageCover && !musicEditDetails?.cover_image) {
    //   getError("Image cover is required.");
    //   return;
    // }
    if (!acceptTerms) {
      getError("you must accept to the terms and conditions.");
      return;
    }

    // if (!songCover && !musicEditDetails?.audio_file) {
    //   getError("Song cover is required.");
    //   return;
    // }

    // if (songCover?.type !== "audio/mpeg" && !musicEditDetails?.audio_file) {
    //   getError("File not supported.");
    //   return;
    // }

    if (musicEditDetails?.cover_image && isEditCoverImage) {
      return uploadCoverImage(imageCover, "", values);
    }

    if (musicEditDetails?.cover_image && !isEditCoverImage) {
      return uploadSongDetails(
        musicEditDetails?.cover_image,
        musicEditDetails?.audio_file,
        values,
      );
    }

    uploadCoverImage(imageCover, songCover, values);
  };

  const uploadSongFormik = useFormik({
    validationSchema: uploadSongValidationSchema,
    initialValues: {
      title: musicEditDetails?.title || "",
      artiste: userProfile?.stageName || userProfile?.userName || "",
      linkToContent: musicEditDetails?.link_content || "",
      directLink: musicEditDetails?.direct_link || "",
      personalMessage: musicEditDetails?.personal_message || "",
    },
    onSubmit: async (values: any) => {
      uploadSong(values);
    },
  });

  // console.log("userProfile", userProfile);

  return (
    <AppModal
      handleClickAway={() => {
        dispatch(setIsShowUploadModal(false));
        dispatch(setMusicEditDetails(null));
      }}
      title="Upload a Song"
    >
      <div className={Styles?.wrapper}>
        <div className={Styles?.wrapper__songCover}>
          <div className={Styles?.wrapper__songTitle}>Song Cover</div>
          <div className="row">
            <div className="col">
              <div className={Styles?.wrapper__songcoverUpload}>
                <AppUploadComponent
                  imageUrl={
                    imageCover?.localUrl || musicEditDetails?.cover_image
                  }
                  icon={<UploadIcon />}
                  title="Upload Song Cover"
                  subTitle="(Upload in PNG or JPEG format)"
                  id="image-cover"
                  accept={"image/png, image/jpeg"}
                  handleChange={(data: any) => {
                    setImageCover({
                      file: data?.target.files[0],
                      localUrl: URL.createObjectURL(data?.target?.files[0]),
                    });
                    setIsEditCoverImage(true);
                  }}
                />
              </div>
            </div>

            <div className="col">
              <div
                className={`${musicEditDetails && Styles?.wrapper__songFileUploadDisabled} ${Styles?.wrapper__songFileUpload}`}
              >
                <AppUploadComponent
                  disabled={musicEditDetails}
                  icon={
                    songCover || musicEditDetails ? (
                      <SongUploadIcon />
                    ) : (
                      <UploadIcon />
                    )
                  }
                  title={songCover ? "Song File" : "Upload Song File"}
                  subTitle="(Upload in Mp3 format)"
                  accept={".mp3,audio/*"}
                  id="song-cover"
                  handleChange={(data: any) => {
                    setSongCover(data?.target.files[0]);
                  }}
                />
              </div>
            </div>
          </div>

          {/* <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={userProfile?.stageName}
              name="artiste"
              title="Artiste"
              disabled
              onChange={uploadSongFormik.handleChange}
              placeholder="Enter artiste name"
              error={
                uploadSongFormik.submitCount > 0 &&
                uploadSongFormik.errors.artiste
              }
            />
          </div> */}

          <div className="row">
            <div className="col-md-6 col" style={{ marginBottom: "28px" }}>
              <TextInput
                value={uploadSongFormik?.values?.title}
                name="title"
                title="Song Title"
                onChange={uploadSongFormik.handleChange}
                placeholder="Enter the title of the song"
                error={
                  uploadSongFormik.submitCount > 0 &&
                  uploadSongFormik.errors.title
                }
              />
            </div>
            <div className="col-md-6 col" style={{ marginBottom: "28px" }}>
              <TextInput
                value={uploadSongFormik?.values?.linkToContent}
                name="linkToContent"
                title="Link to Content"
                onChange={uploadSongFormik.handleChange}
                placeholder="Enter link to Content"
                error={
                  uploadSongFormik.submitCount > 0 &&
                  uploadSongFormik.errors.linkToContent
                }
              />
            </div>
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={uploadSongFormik?.values?.personalMessage}
              name="personalMessage"
              title="Personal message"
              onChange={uploadSongFormik.handleChange}
              placeholder="Enter personal message"
              error={
                uploadSongFormik.submitCount > 0 &&
                uploadSongFormik.errors.personalMessage
              }
            />
          </div>
          <div style={{ marginBottom: "28px" }}>
            <TextInput
              value={uploadSongFormik?.values?.directLink}
              name="directLink"
              title="Direct Link"
              onChange={uploadSongFormik.handleChange}
              placeholder="Enter direct Link"
              error={
                uploadSongFormik.submitCount > 0 &&
                uploadSongFormik.errors.directLink
              }
            />
          </div>

          {/* <div className={Styles?.wrapper__songTitle}>Song File</div> */}

          <div className={Styles.wrapper__checkContainer}>
            <CheckInput
              isChecked={acceptTerms}
              onChange={() => {
                setAcceptTerms(!acceptTerms);
              }}
              label={
                <div className={Styles.wrapper__check}>
                  I have read the{" "}
                  <span>
                    <Link
                      to="https://audoji-frontend-staging.vercel.app/terms-and-conditions"
                      target={"_blank"}
                    >
                      Terms and Conditions
                    </Link>
                  </span>{" "}
                  and I warrant that I have all necessary rights, permissions
                  and consents to upload and share the content.
                </div>
              }
            />
          </div>
          <div className={Styles.wrapper__button}>
            <Button
              title="Upload Song"
              onClick={uploadSongFormik?.handleSubmit}
              loading={loader}
            />
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default UploadSongModal;
