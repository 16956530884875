import React from "react";
import AvatarImage from "../../assets/images/empty-song-image.png";
import DeleteIcon from "../../assets/svgs/DeleteIcon";
import EditIcon from "../../assets/svgs/EditIcon";

import Styles from "./SoundBoardCard.module.scss";
interface ISoundBoardCard {
  image: any;
  title: string;
  height?: string;
  divIndex: number;
  dataLength?: number;
  key: any;
  handleSelect: any;
  selectedSong: any;
  handleEdit: () => void;
  handleDelete: () => void;
  selectedSongId: any;
}
const SoundBoardCard = ({
  image,
  title,
  height,
  divIndex,
  dataLength,
  key,

  handleSelect,
  selectedSong,
  selectedSongId,
  handleDelete,
  handleEdit,
}: ISoundBoardCard) => {
  return (
    <div key={key}>
      <div
        className={`${Styles.wrapper} ${selectedSong === title ? Styles.wrapper__selectedContainer : ""}`}
        style={{ height }}
        onClick={() => handleSelect(title, selectedSongId)}
      >
        <div className={`${Styles.wrapper__imageContainer}`}>
          <img
            alt=""
            src={image?.includes("null") ? AvatarImage : image}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div className={Styles.wrapper__titleContainer}>
          <div
            className={`${Styles.wrapper__imageTitle} ${selectedSong === selectedSongId ? Styles.wrapper__selectedTitle : ""}`}
          >
            {title}
          </div>
          <div className={Styles.wrapper__editIcon}>
            <span onClick={handleEdit}>
              <EditIcon width="16px" height="16px" color="#909090" />
            </span>
          </div>
          <div className={Styles.wrapper__deleteIcon}>
            <span onClick={handleDelete}>
              <DeleteIcon width="16px" height="16px" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundBoardCard;
